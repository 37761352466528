.about-me {
  flex-direction: column;
  padding: 106px 70px 98px;
}

.about-me__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-me__container {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.about-me__title {
  margin: 0;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin-top: -5px;
  padding-bottom: 18px;
  letter-spacing: -0.04em;
}

.about-me__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 26px;
}

.about-me__text {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 96px;
  max-width: 599px;
}

.about-me__photo-container {
  margin-top: -5px;
  width: 270px;
  height: 325px;
  border-radius: 10px;
}

.about-me__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-me__link {
  list-style-type: none;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: black;
}

@media(max-width: 768px) {
  .about-me {
    padding: 88px 50px 90px;
  }

  .about-me__title {
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 16px;
  }

  .about-me__subtitle {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 20px;
  }

  .about-me__text {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 87px;
    max-width: 363px;
  }

  .about-me__photo-container {
    width: 255px;
    height: 307px;
  }

  .about-me__link {
    line-height: 17px;
  }
}

@media(max-width: 470px) {
  .about-me__wrapper {
    flex-direction: column-reverse;
  }

  .about-me__photo-container {
    margin-bottom: 44px;
  }
}

@media(max-width: 320px) {
  .about-me {
    padding: 70px 14px 70px;
  }

  .about-me__photo-container {
    width: 292px;
    height: 352px;
    margin-top: 0;
  }

  .about-me__title {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px;
  }

  .about-me__subtitle {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 20px;
  }

  .about-me__text {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 40px;
  }
}