.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  visibility: hidden;
  display: flex;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  background-color: #fff;
  max-width: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.popup__image {
  max-width: 120px;
  width: 100%;
  max-height: 120px;
  height: 100%;
  padding-top: 60px;
}

.popup__text {
  color: #000;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  width: 358px;
  margin: 0;
  padding-bottom: 60px;
  padding-top: 32px;
  text-align: center;
}

.popup__close-button {
  all: unset;
  position: absolute;
  top: -40px;
  right: -40px;
  background-image: url(../../images/popup_close_button.svg);
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: opacity .4s ease;
}

.popup__close-button:hover {
  opacity: .6;
}

@media screen and (max-width: 530px) {
  .popup__container {
    max-width: 282px;
  }

  .popup__close-button {
    top: -36px;
    right: 0px;
    background-image: url(../../images/popup_close_button_thick.svg);
    width: 20px;
    height: 20px;
  }
}