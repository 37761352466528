.section {
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 1280px;
}

.section__title {
  margin: 0 0 70px;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  padding-bottom: 23px;
  border-bottom: 1px solid black;
  letter-spacing: -0.04em;
}

@media(max-width: 320px) {
  .section__title {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 28px;
  }
}