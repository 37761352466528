.profile {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.profile__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  padding: 71px 0px 122px;
}

.profile__form {
  max-width: 410px;
  width: 100%;
}

.profile__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.profile__label {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E8E8E8;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  padding: 0;
}

.profile__label:last-of-type {
  border-bottom-color: white;
}

.profile__label:hover {
  border-bottom: 1px solid #3456F3;
}

.profile__input {
  border: none;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  padding: 0 0 16px;
  width: 90%;
}

.profile__input:focus {
  outline: none;
}

.profile__input:disabled {
  background-color: white;
}

.profile__error {
  display: block;
  min-height: 17px;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #FF004C;
}

.profile__error_position_low {
  margin-top: 153px;
}

.profile__error_hidden {
  visibility: hidden;
}

.profile__buttons {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.profile__button {
  all: unset;
  font-size: 13px;
  line-height: 16px;
}

.profile__button_type_edit {
  margin: 187px 0px 16px;
  font-weight: 400;
}

.profile__button_type_logout {
  font-weight: 500;
  margin-bottom: 70px;
  color: #FF3055;
}

.profile__save-button {
  margin: 20px 0px 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background-color: #3456F3;
  color: white;
  width: 410px;
  padding: 13px 0;
  border-radius: 3px;
  border: none;
}

.profile__save-button_disabled {
  background-color: #F8F8F8;
  color: #C2C2C2;
}

@media(max-width: 768px) {
  .profile__title {
    padding-top: 232px;
    padding-bottom: 96px;
  }

  .profile__button_type_edit {
    margin-top: 178px;
  }

  .profile__input {
    width: 85%;
  }
}

@media(max-width: 450px) {
  .profile__form {
    max-width: 260px;
  }

  .profile__input {
    width: 85%;
  }

  .profile__save-button {
    width: 260px;
  }

  .profile__title {
    padding: 67px 58px 80px;
  }

  .profile__button {
    font-size: 12px;
    line-height: 15px;
  }

  .profile__button_type_edit {
    margin-top: 335px;
  }
}