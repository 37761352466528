.notfound {
  flex-direction: column;
  align-items: center;
}

.notfound__title {
  margin: 0;
  padding-top: 246px;
  font-weight: 400;
  font-size: 140px;
  line-height: 170px;
}

.notfound__text {
  margin: 0;
  padding-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.notfound__link {
  margin: 0;
  padding-top: 184px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3456F3;
}

@media(max-width: 768px) {
  .notfound__title {
    padding-top: 408px;
  }
}

@media(max-width: 320px) {
  .notfound__title {
    padding-top: 329px;
    font-size: 80px;
    line-height: 97px;
  }

  .notfound__text {
    font-size: 12px;
    line-height: 15px;
    padding-top: 10px;
  }

  .notfound__link {
    font-size: 12px;
    line-height: 15px;
    padding-top: 284px;
  }
}