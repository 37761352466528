.promo {
  background-color: #073042;
  padding: 70px 70px 40px;
}

.promo__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.promo__container:after {
  content: "";
  background-image: url(../../images/promo_logo_Earth.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 310px;
  width: 304px;
}

.promo__box {
  flex-direction: column;
}

.promo__title {
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  color: #FFF;
  max-width: 640px;
  margin: 0;
  padding-bottom: 36px;
  hyphens: none;
}

.promo__nohyphen {
  white-space: nowrap;
}

.promo__text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #E5E5E5;
  max-width: 310px;
  margin: 0;
  opacity: .5;
}

@media(max-width: 1100px) {
  .promo__container {
    padding: 62px 30px 49px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .promo__container:after {
    margin-bottom: 48px;
  }

  .promo__box {
    display: flex;
    align-items: center;
  }

  .promo__title {
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 22px;
  }

  .promo__text {
    text-align: center;
    max-width: 260px;
  }
}

@media(max-width: 550px) {
  .promo {
    padding: 4px 10px 80px;
  }

  .promo__container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .promo__container:after {
    background-size: 210px 206px;
    width: 210px;
    height: 206px;
    margin-bottom: 44px;
  }

  .promo__title {
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 24px;
    letter-spacing: -0.04em;
  }
}