.searchform {
  flex-direction: column;
  padding: 65px 70px 48px;
}

.searchform__form {
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  margin-bottom: 4px;
}

.searchform__input {
  border: none;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  width: 89%;
}

.searchform__input:focus {
  outline: none;
}

.searchform__form:hover {
  border-bottom: 1px solid #3456F3;
}

.searchform__button {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #FFF;
  background-color: #3456F3;
  border-radius: 48px;
  border: none;
  height: 34px;
  width: 59px;
}

.searchform__button:hover {
  cursor: pointer;
  opacity: .8;
}

.searchform__error {
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  margin-bottom: 18px;
  color: #FF004C;
}

.searchform__error_hidden {
  visibility: hidden;
}

.searchform__container {
  display: flex;
  flex-direction: row;
}

@media(max-width: 768px) {
  .searchform {
    padding: 75px 30px 61px;
  }

  .searchform__container {
    flex-direction: row-reverse;
  }

  .searchform__error {
    margin-bottom: 16px;
  }

  .searchform__input {
    width: 88%;
  }

  .searchform__text {
    padding-left: 0px;
    padding-right: 17px;
  }
}

@media(max-width: 600px) {
  .searchform__input {
    width: 80%;
  }
}

@media(max-width: 450px) {
  .searchform__input {
    width: 74%;
  }
}

@media(max-width: 320px) {
  .searchform {
    padding: 81px 14px 10px;
  }

  .searchform__form {
    padding-bottom: 8px;
  }

  .searchform__error {
    margin-bottom: 25px;
  }

  .searchform__input {
    font-size: 18px;
    line-height: 22px;
  }

  .searchform__container {
    justify-content: center;
  }

  .searchform__text {
    font-size: 11px;
  }
}