.filter-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.filter-checkbox+label {
  position: relative;
  padding: 0 0 0 50px;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.filter-checkbox+label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 14px;
  border-radius: 13px;
  background-color: #EBEBEB;
  transition: .2s;
}

.filter-checkbox+label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #F5F5F5;
  transition: .2s;
}

.filter-checkbox:checked+label:before {
  background-color: #2BE080;
}

.filter-checkbox:checked+label:after {
  left: 21px;
  background-color: white;
}

@media(max-width: 768px) {
  .filter-checkbox+label {
    padding-left: 0;
    padding-right: 52px;
  }

  .filter-checkbox+label:before {
    top: 2px;
    left: 134px;
  }

  .filter-checkbox+label:after {
    top: 4px;
    left: 137px;
  }

  .filter-checkbox:checked+label:after {
    left: 156px;
  }
}

@media(max-width: 320px) {
  .filter-checkbox+label {
    font-size: 11px;
  }

  .filter-checkbox+label:before {
    left: 115px;
  }

  .filter-checkbox+label:after {
    left: 118px;
  }

  .filter-checkbox:checked+label:after {
    left: 137px;
  }
}